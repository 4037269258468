import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../app";
import { ROUTES } from "../data/conf";
import { supabase } from "../supabaseClient";

export default function Navbar() {
  const { user } = useContext(AppContext);

  function handleLogout() {
    supabase.auth.signOut();
  }

  return (
    <div className="navbar bg-base-300">
      <div className="flex-1">
        <Link to="/" className="btn btn-ghost text-xl">
          Home
        </Link>
      </div>
      <div className="flex-none">
        <ul className="menu menu-horizontal px-1">
          {ROUTES.filter((v) => v.navTitle).map((v, i) => (
            <li key={i}>
              <Link to={v.path}>{v.navTitle}</Link>
            </li>
          ))}
          <li>
            <details>
              <summary>{user.full_name}</summary>
              <ul className="p-2 bg-base-100 rounded-t-none border z-30 border-t-0">
                {ROUTES.filter((v) => v.navStaffTitle).map((v, i) => (
                  <li key={i}>
                    <Link to={v.path}>{v.navStaffTitle}</Link>
                  </li>
                ))}
                <li>
                  <button onClick={handleLogout}>Log Out</button>
                </li>
              </ul>
            </details>
          </li>
        </ul>
      </div>
    </div>
  );
}

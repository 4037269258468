import { useState } from "react";
import { EVENTS } from "../data/events";
import { addMonths, format, getDaysInMonth, setDate } from "date-fns";
import { FaCheck } from "react-icons/fa"
import { supabase } from "../supabaseClient";
import Swapper from "../components/swapper";

let WEEKDAYS = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
let MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

//Add a feature on calendar to highlight the date of all the days with event
// If i sort out "fundraisers", and the event only contained fundraisers before, it should not highlight the date while I am not 
// viewing fundriasers 
export default function Events() {
  let [d, setD] = useState(new Date());
  let [selectedDate, setSelectedDate] = useState(new Date().getDate());

  function prev() {
    setD(addMonths(d, -1));
  }
  function nxt() {
    setD(addMonths(d, 1));
  }
  function now() {
    setD(new Date());
    setSelectedDate(new Date().getDate())
  }

  let first = setDate(d, 1);

  let circledDate = setDate(d, selectedDate);
  let todaysEvents = EVENTS.filter((e) => e.date === format(circledDate, "yyyy/MM/dd"));

  return (

    <div className="flex w-screen">
      <div className="w-1/2">
        <div className="flex flex-col items-center  justify-center">
          <div className="flex justify-center my-5 font-bold">
            {MONTHS[format(d, "MM") - 1] + " " + format(d, "yyyy")}
          </div>
          <div className="flex justify-center space-x-2">
            <button className="btn btn-neutral btn-sm" onClick={prev}>
              <i className="fa-solid fa-arrow-left"></i>
              Previous
            </button>
            <button className="btn btn-neutral btn-sm" onClick={now}>
              NOW
            </button>
            <button className="btn btn-neutral btn-sm" onClick={nxt}>
              Next
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </div>
          <div className="flex flex-wrap my-5 w-72">
            {WEEKDAYS.map((v, i) => (
              <div className="text-center w-10" key={i}>
                {v}
              </div>
            ))}
            {new Array(first.getDay()).fill(0).map((v, i) => (
              <button className="w-10" key={i}></button>
            ))}
            {new Array(getDaysInMonth(d)).fill(0).map((v, i) => (
              <button
                className="flex justify-center items-center w-10"
                onClick={() => setSelectedDate(i + 1)}
                key={i}
              >
                <div
                  className={`w-8 h-8 flex justify-center items-center ${i + 1 === selectedDate && "bg-primary rounded-full"
                    }`}
                >
                  {i + 1}
                </div>
              </button>
            ))}
          </div>
        </div>
        <div className="flex flex-col items-center justify-center space-y-5 mb-5">
          <div className="font-bold"></div>
          <div >
            {todaysEvents.map((e, i) => (
              <div
                key={i}
                className="w-full bg-primary px-5 py-2 rounded-lg mb-5 text-center"
              >
                {e.title}
              </div>
            ))}
          </div>
        </div>
        {/* Sorts */}
        <div className="flex justify-center">
          <button
            className="btn"
            onClick={() => document.getElementById("my_modal_3").showModal()}
          >
            Sort Notifications
          </button>
          <dialog id="my_modal_3" className="modal">
            <div className="modal-box">
              <form method="dialog">
                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                  ✕
                </button>
              </form>
              <Swapper on={"Viewing Monthly Meetings"} off={"Hiding Monthly Meetings"} />
              <br />
              <Swapper on={"Viewing Other Meetings"} off={"Hiding Other Meetings"} />
              <br />
              <Swapper on={"Viewing Announcements"} off={"Hiding Announcements"} />
              <br />
              <Swapper on={"Viewing Fundraisers"} off={"Hiding Fundraisers"} />
              <br />
              <Swapper on={"Viewing Other"} off={"Hiding Other"} />
            </div>
          </dialog>
        </div>
      </div>
      <div>
        Selected Date <br/>
        {/* You can open the modal using document.getElementById('ID').showModal() method */}
        <button className="btn" onClick={() => document.getElementById('my_modal_4').showModal()}>Change Bell Schedule</button>
        <dialog id="my_modal_4" className="modal">
          <div className="modal-box w-11/12 max-w-5xl">
            <h3 className="font-bold text-lg">Hello!</h3>
            <p className="py-4">
              <button class="btn btn-outline btn-info">Default Blue</button>
              <button class="btn btn-outline btn-warning">Default Gold</button>
              <button className="btn btn-outline btn-error">No School</button>
            </p>
            <div className="modal-action">
              <form method="dialog">
                {/* if there is a button, it will close the modal */}
                <button className="btn">Save</button>
              </form>
            </div>
          </div>
        </dialog>
        <br/>
        Card for all the announcements of the day.
      </div>
    </div>

  );
}

import { useContext, useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { supabase } from "../supabaseClient";
import { AppContext } from "../app";
import { Link } from "react-router-dom";

export default function Clubs() {
  return (
    <div className="w-screen h-screen relative flex" style={{ backgroundColor: "#162049" }}>
      <style jsx>{`
        @media (max-width: 1024px) {
          .left-box {
            display: none;
          }
          .divider {
            display: none;
          }
        }
      `}</style>
      {/* Left Box */}
      <div className="left-box w-4/12 h-screen mx-16 my-6">
        <div className="font-bold text-3xl">CLUBNAME</div>
        <br/>
        {/* Club Name Change */}
        <div >Change Club Name</div>
        <div>
          <input type="text" placeholder="Type here" className="input input-bordered input-info w-full max-w-xs" />
        </div>
        <br/>
        {/* Club Photo Change */}
        <div>Update Club Photo</div>
        <input type="file" className="file-input file-input-bordered file-input-success w-full max-w-xs" />
      </div>
      {/* Divider */}
      <div className="divider w-1 h-screen" style={{ backgroundColor: "#ffc80c" }}></div>
      {/* Right Box */}
      <div className="w-full lg:w-7/12 h-screen mx-16 my-6">
        {/* Card */}
        <div className="card w-196 text-primary-content" style={{ backgroundColor: "#808080" }}>
          <div className="card-body">
            <h2 className="card-title flex justify-between" style={{ color: "#ffc80c" }}>
              <div>Create Announcements! </div>
              <div className="dropdown" >
                <div tabIndex={0} role="button" className="btn m-1" style={{ color: "#162049", backgroundColor: "#808080" }}>Announcement Tags ▼</div>
                <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                  <li><a>Club Meeting</a></li>
                  <li><a>Announcements</a></li>
                  <li><a>Fundraisers</a></li>
                  <li><a>Other</a></li>
                </ul>
              </div>
            </h2>
            {/* Date Select */}
            <form>
              <label>
                Date this notification is relevant until:
                <input type="date" name="date" className="text-purple-400" />
              </label>
            </form>
            <br/>
            <input type="text" placeholder="Event Details (Reminder: Include the time)" className="input input-bordered input-md w-full max-w-xs text-purple-300" />

            <div className="card-actions justify-end">
              <button className="btn" style={{ color: "#ffc80c", backgroundColor: "#808080" }} >Upload</button>
            </div>
          </div>
        </div>
        <br/>
        <button className="btn m-5" onClick={() => document.getElementById('my_modal_1').showModal()}>View Posted Notifications!</button>
        <dialog id="my_modal_1" className="modal">
          <div className="modal-box">
            <h3 className="font-bold text-lg">All Uploaded Notifications</h3>
            <p className="py-4">Fill with Cards, Add a feature to delete the notifications.</p>
            <div className="modal-action">
              <form method="dialog">
                {/* if there is a button in form, it will close the modal */}
                <button className="btn">Close</button>
              </form>
            </div>
          </div>
        </dialog>
        {/* The button to open modal */}
        <label htmlFor="my_modal_7" className="btn">Fundraiser Inquiry</label>
        {/* Put this part before </body> tag */}
        <input type="checkbox" id="my_modal_7" className="modal-toggle" />
        <div className="modal" role="dialog">
          <div className="modal-box">
            <h3 className="text-lg font-bold">Schedule your Fundraiser</h3>
            <p className="py-4">~~~~~~~~~~~~~~~</p>
          </div>
          <label className="modal-backdrop" htmlFor="my_modal_7">Close</label>
        </div>
      </div>
    </div>
  );
}

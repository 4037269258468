const EVENTS =
  [
    {
      "club_id": 1,
      "body": "First Day of School!",
      "date": "2024/08/13",
      "postedBy": "CLHS",
      "title": "First day of school"
    },
    {
      "club_id": 1,
      "body": "Come get a cookie, at the field next to A Building if you are in a Cohort!",
      "date": "2024/02/01",
      "postedBy": "Cohorts",
      "title": "Cohort"
    },
    {
      "club_id": 1,
      "body": "Club meeting for the 'USACO' Club ",
      "date": "2024/02/01",
      "postedBy": "USACO Club",
      "title": "Club Meetings"
    },
    {
      "club_id": 2,
      "body": "Book club meeting to discuss 'To Kill a Mockingbird'",
      "date": "2024/02/03",
      "postedBy": "Book Club",
      "title": "Book Club Meeting"
    },
    {
      "club_id": 1,
      "body": "Movie night at the auditorium, featuring 'Jurassic Park'",
      "date": "2024/02/04",
      "postedBy": "Film Society",
      "title": "Movie Night"
    },
    {
      "club_id": 1,
      "body": "Art exhibition showcasing student creations",
      "date": "2024/02/05",
      "postedBy": "Art Club",
      "title": "Art Exhibition"
    },
    {
      "club_id": 2,
      "body": "Book fair happening in the library today! Don't miss it.",
      "date": "2024/02/02",
      "postedBy": "Library",
      "title": "Book Fair"
    },
    {
      "club_id": 1,
      "body": "Soccer practice will be held at the soccer field after school.",
      "date": "2024/02/02",
      "postedBy": "Soccer Team",
      "title": "Soccer Practice"
    },
    {
      "club_id": 1,
      "body": "Art exhibition showcasing local artists' work in the auditorium.",
      "date": "2024/02/03",
      "postedBy": "Art Club",
      "title": "Art Exhibition"
    },
    {
      "club_id": 1,
      "body": "Student council meeting in the student lounge. All are welcome!",
      "date": "2024/02/03",
      "postedBy": "Student Council",
      "title": "Student Council Meeting"
    },
    {
      "club_id": 2,
      "body": "Science club members, join us for a stargazing event tonight.",
      "date": "2024/02/04",
      "postedBy": "Science Club",
      "title": "Stargazing"
    },
    {
      "club_id": 2,
      "body": "Parent-teacher conferences scheduled for next week. Check the website for details.",
      "date": "2024/02/05",
      "postedBy": "School Administration",
      "title": "Parent-Teacher Conferences"
    },
    {
      "club_id": 1,
      "body": "Don't forget to submit your yearbook photos by the end of the week.",
      "date": "2024/02/06",
      "postedBy": "Yearbook Committee",
      "title": "Yearbook Photos"
    },
    {
      "club_id": 1,
      "body": "Halloween costume contest on October 31st. Prizes for the best costumes!",
      "date": "2024/02/07",
      "postedBy": "Student Council",
      "title": "Halloween Costume Contest"
    },
    {
      "club_id": 1,
      "body": "Career fair in the gymnasium. Explore different career options!",
      "date": "2024/02/09",
      "postedBy": "Career Services",
      "title": "Career Fair"
    },
    {
      "club_id": 1,
      "body": "Reminder: Daylight Saving Time ends this weekend. Set your clocks back one hour.",
      "date": "2024/02/12",
      "postedBy": "School Administration",
      "title": "Daylight Saving Time Ends"
    }
  ]

export { EVENTS };
import { useState } from "react";
import { supabase } from "./supabaseClient";

export default function Auth() {
  return (
    <div className="h-screen flex-col center">
      <AuthForm title="Sign In" />
      <div className="divider mb-10">OR</div>
      <AuthForm title="Sign Up" />
    </div>
  );
}

function AuthForm({ title }) {
  let [loading, setLoading] = useState(false);
  let [errMsg, setErrMsg] = useState(undefined);

  async function handleSignup(ev) {
    setLoading(true);
    setErrMsg(undefined);
    ev.preventDefault();
    const formData = {
      email: ev.target.email.value,
      password: ev.target.password.value,
    };
    if (title === "Sign Up")
      Object.assign(formData, {
        options: {
          data: {
            grad_year: parseInt(ev.target.grad_year.value),
            full_name: ev.target.full_name.value,
          },
        },
      });

    let { error } =
      title === "Sign Up"
        ? await supabase.auth.signUp(formData)
        : await supabase.auth.signInWithPassword(formData);
    if (error) setErrMsg(error.message);
    setLoading(false);
  }

  return (
    <div>
      <h1 className="center">{title}</h1>
      <form className="flex flex-col p-3 gap-3" onSubmit={handleSignup}>
        <input
          name="email"
          className="input input-bordered"
          type="email"
          placeholder="Email"
        />
        <input
          name="password"
          className="input input-bordered"
          type="password"
          placeholder="Password"
        />
        {title === "Sign Up" && (
          <>
            <input
              name="full_name"
              className="input input-bordered"
              type="test"
              placeholder="Full Name"
            />
            <input
              name="grad_year"
              className="input input-bordered"
              type="number"
              placeholder="Graduation Year"
            />
          </>
        )}
        <button className="btn btn-primary" disabled={loading}>
          {title}
        </button>
        <div className="text-error">{errMsg}</div>
      </form>
    </div>
  );
}

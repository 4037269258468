import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./layout/layout";
import { ROUTES } from "./data/conf";
import { createContext, useEffect, useState } from "react";
import Auth from "./auth";
import { supabase } from "./supabaseClient";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: ROUTES,
  },
]);

export const AppContext = createContext(null);

export default function App() {
  let [user, setUser] = useState(null);

  async function getUserData(id) {
    let { data } = await supabase
      .from("profiles")
      .select("*, roles(is_admin)")
      .eq("id", id)
      .single();
    setUser(data);
  }

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        if (session) {
          getUserData(session.user.id);
        } else {
          setUser(null);
        }
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  if (user)
    return (
      <AppContext.Provider value={{ user }}>
        <RouterProvider router={router} />
      </AppContext.Provider>
    );
  else return <Auth />;
}

import Index from "../routes";
import Clubs from "../routes/clubs";
import Events from "../routes/events";

const TITLE = "School App";
const ROUTES = [
  { path: "/", element: <Index /> },
  { path: "/events", navTitle: "Events", element: <Events /> },
  { path: "/clubs", navTitle: "Clubs", element: <Clubs /> },
];

export { ROUTES, TITLE };

// path: the url to the page
// title: leave undefined if you do NOT want it to show in the navbar
// element: JSX component, needs to be imported
